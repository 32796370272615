import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import InstagramIcon from "@mui/icons-material/Instagram";
import FeedItem from "./FeedItem";
import MessageModal from "../utility/MessageModal";
import {
  checkInsta,
  deleteFeed,
  getFeedList,
  getInstaImageList,
  getInstaImageList2,
  getInstaImageList3,
} from "../../apis/feed";
import { useRecoilState } from "recoil";
import { MyShopIsEdit, ShopData } from "../../atoms/atom";
import { FeedType } from "../../types";
import EditFeed from "./EditFeed";
import AddFeed from "./AddFeed";
import InstaUploadModal from "./InstaUploadModal";
import { refreshInstaToken } from "../../apis/auth";

const Portfolio = () => {
  const [shopData] = useRecoilState(ShopData);
  const [, setMyShopIsEdit] = useRecoilState(MyShopIsEdit);

  const [feedList, setFeedList] = useState<FeedType[]>([]);

  const [openOption, setOpenOption] = useState(false);
  const [openInstaUploadModal, setOpenInstaUploadModal] = useState(false);
  const [showFailMessage, setShowFailMessage] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  const feedListBoxRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [instaImgList, setInstaImgList] = useState<
    { id: string; url: string }[]
  >([]);

  const [response, setResponse] = useState<any>();

  const [data, setData] = useState<FeedType>({
    name: "",
    category: "",
    color: "",
    id: "",
    imageUrl: "",
    isPinned: false,
    likeCount: 0,
    price: "",
    runningTime: "",
    shopId: "",
    feedTagList: [],
    instagramMediaId: null,
  });

  const AddInstaList = (list: any) => {
    list.forEach((e: any) => {
      if (e.media_type === "IMAGE") {
        if (
          feedList.filter((item) => item.instagramMediaId === e.id).length === 0
        ) {
          setInstaImgList((old) => [...old, { id: e.id, url: e.media_url }]);
        }
      } else if (e.media_type === "VIDEO") {
        if (
          feedList.filter((item) => item.instagramMediaId === e.id).length === 0
        ) {
          setInstaImgList((old) => [
            ...old,
            { id: e.id, url: e.thumbnail_url },
          ]);
        }
      } else if (e.media_type === "CAROUSEL_ALBUM") {
        getInstaImageList2(shopData.instagramRefreshToken!, e.id)
          .then((res) => res.data.data)
          .then((res) => {
            const resultList2 = res;
            resultList2.forEach((e2: any) => {
              if (e2.media_type === "IMAGE") {
                if (
                  feedList.filter((item) => item.instagramMediaId === e2.id)
                    .length === 0
                ) {
                  setInstaImgList((old) => [
                    ...old,
                    { id: e2.id, url: e2.media_url },
                  ]);
                }
              } else if (e.media_type === "VIDEO") {
                if (
                  feedList.filter((item) => item.instagramMediaId === e.id)
                    .length === 0
                ) {
                  setInstaImgList((old) => [
                    ...old,
                    { id: e.id, url: e.thumbnail_url },
                  ]);
                }
              }
            });
          });
      }
    });
  };

  const getNextList = (res: any) => {
    if (res.paging.next) {
      getInstaImageList3(res.paging.next).then((res) => {
        AddInstaList(res.data.data);
        //getNextList(res.data);

        console.log("next :", res);
        setResponse(res.data);
      });
    }
  };

  useEffect(() => {
    getFeedList(shopData.id).then((res) => {
      setFeedList(res.data.data.feedList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {
    if (feedListBoxRef.current) {
      setScrollPosition(feedListBoxRef.current.scrollTop);
    }
  };

  useEffect(() => {
    console.log(scrollPosition);
    if (!isEdit && feedListBoxRef.current && scrollPosition !== 0) {
      feedListBoxRef.current!.scrollTop = scrollPosition;
    }

    const feedListBoxElement = feedListBoxRef.current;
    if (feedListBoxElement) {
      feedListBoxElement.addEventListener("scroll", handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (feedListBoxElement) {
        feedListBoxElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isEdit, scrollPosition]);

  return (
    <Container>
      <PortfolioAddBtn onClick={() => setOpenOption((old) => !old)}>
        포트폴리오 +
      </PortfolioAddBtn>
      {openOption && (
        <AddOption>
          <div
            onClick={() => {
              setMyShopIsEdit(true);
              setIsAdd(true);
              setOpenOption(false);
            }}
          >
            <AddPhotoAlternateIcon />
            <span>사진업로드</span>
          </div>
          <div
            onClick={() => {
              checkInsta()
                .then((res) => res.data)
                .then((res) => {
                  console.log(res);
                  if (res) {
                    // 업로드
                    setInstaImgList([]);

                    getInstaImageList(
                      shopData.instagramRefreshToken!,
                      shopData.id
                    )
                      .then((res) => res.data)
                      .then((res) => {
                        const resultList = res.data;

                        AddInstaList(resultList);
                        setResponse(res);

                        console.log(res);
                        getNextList(res);

                        setOpenInstaUploadModal(true);

                        refreshInstaToken();
                      })
                      .catch((e) => {
                        console.log(e);
                        if (e.response.data.statusCode === 401) {
                          window.alert("다시 한번 시도해 주세요.");
                        }
                      });
                  } else {
                    // 연동진행

                    // 개발계 테스트
                    /*window.location.href = `https://www.instagram.com/oauth/authorize/third_party/?client_id=417135714178566&redirect_uri=https%3A%2F%2Fdev-api.finger-princess.com%2Fmember%2Finstagram%2Fcallback&scope=user_profile%2Cuser_media&response_type=code&state=${shopData.id}`;*/

                    // 운영계
                    window.location.href = `https://www.instagram.com/oauth/authorize/third_party/?client_id=956695739119427&redirect_uri=https%3A%2F%2Fapi.finger-princess.com%2Fmember%2Finstagram%2Fcallback&scope=user_profile%2Cuser_media&response_type=code&state=${shopData.id}`;

                    /*const result = window.prompt(
                      "선택하신 기능을 이용하려면,핑프메이커ID와 인스타그램 계정을 연결해 주세요.인스타그램 계정과의 연동을 통해 핑프메이커 서비스가 제공됩니다."
                    );

                    if (!result) return;
                    

                    saveShopData({
                      shopUserId: shopData.shopUserId,
                      id: shopData.id,
                      imageUrlList: shopData.imageUrlList,
                      name: shopData.name,
                      phoneNumber: shopData.phoneNumber,
                      basicAddress: shopData.basicAddress,
                      detailAddress: shopData.detailAddress,
                      coordinate: shopData.coordinate,
                      announcement: shopData.announcement,
                      info: shopData.info,
                      businessHourList: shopData.businessHourList,
                      deposit: shopData.deposit,
                      bookingOption: shopData.bookingOption,
                      localTag: shopData.localTag,
                      subwayTag: shopData.subwayTag,
                      instagramId: result,
                      instagramRefreshToken: shopData.instagramRefreshToken,
                      titleMenuId: shopData.titleMenuId,
                      titleImageUrl: shopData.titleImageUrl,
                      isOnlyKakao: shopData.isOnlyKakao,
                      kakaoLink: shopData.kakaoLink,
                    }).then((res) => {
                      // 개발계 테스트
                      window.location.href = `https://www.instagram.com/oauth/authorize/third_party/?client_id=417135714178566&redirect_uri=https%3A%2F%2Fdev-api.finger-princess.com%2Fmember%2Finstagram%2Fcallback&scope=user_profile%2Cuser_media&response_type=code&state=${shopData.id}`;

                      window.location.href =
                        "https://www.instagram.com/oauth/authorize/third_party/?client_id=956695739119427&redirect_uri=https%3A%2F%2Fapi.finger-princess.com%2Fmember%2Finstagram%2Fcallback&scope=user_profile%2Cuser_media&response_type=code";
                    });*/
                  }
                });
            }}
          >
            <InstagramIcon />
            <span>인스타그램</span>
          </div>
        </AddOption>
      )}
      {isAdd ? (
        <AddFeed
          AddCallback={() => {
            getFeedList(shopData.id).then((res) => {
              setFeedList(res.data.data.feedList);
            });

            setData({
              name: "",
              category: "",
              color: "",
              id: "",
              imageUrl: "",
              isPinned: false,
              likeCount: 0,
              price: "",
              runningTime: "",
              shopId: "",
              feedTagList: [],
              instagramMediaId: null,
            });
            setMyShopIsEdit(false);
            setIsAdd(false);
            setIsEdit(false);
          }}
          cancleCallback={() => {
            setData({
              name: "",
              category: "",
              color: "",
              id: "",
              imageUrl: "",
              isPinned: false,
              likeCount: 0,
              price: "",
              runningTime: "",
              shopId: "",
              feedTagList: [],
              instagramMediaId: null,
            });

            setMyShopIsEdit(false);
            setIsAdd(false);
            setIsEdit(false);
          }}
        />
      ) : isEdit ? (
        <EditFeed
          data={data}
          editCallback={() => {
            getFeedList(shopData.id).then((res) => {
              setFeedList(res.data.data.feedList);
            });

            setData({
              name: "",
              category: "",
              color: "",
              id: "",
              imageUrl: "",
              isPinned: false,
              likeCount: 0,
              price: "",
              runningTime: "",
              shopId: "",
              feedTagList: [],
              instagramMediaId: null,
            });

            setMyShopIsEdit(false);
            setIsEdit(false);
          }}
          cancleCallback={() => {
            setData({
              name: "",
              category: "",
              color: "",
              id: "",
              imageUrl: "",
              isPinned: false,
              likeCount: 0,
              price: "",
              runningTime: "",
              shopId: "",
              feedTagList: [],
              instagramMediaId: null,
            });

            setMyShopIsEdit(false);
            setIsEdit(false);
          }}
        />
      ) : feedList.length === 0 ? (
        <NothingText>샵 포트폴리오를 추가해주세요.</NothingText>
      ) : (
        <FeedListBox ref={feedListBoxRef}>
          {feedList.map((item, idx) => (
            <FeedItem
              key={item.id}
              data={item}
              setShowFailMessage={setShowFailMessage}
              editCallback={() => {
                setData(item);

                setMyShopIsEdit(true);
                setIsEdit(true);
              }}
              callback={() => {
                deleteFeed({ feedIdList: [item.id] }).then((res) => {
                  getFeedList(shopData.id).then((res) => {
                    setFeedList(res.data.data.feedList);
                  });
                });
              }}
            />
          ))}
        </FeedListBox>
      )}
      <MessageModal
        showFailMessage={showFailMessage}
        text="피드가 삭제되었습니다."
        left="438px"
        bottom="-20px"
      />
      {openInstaUploadModal && (
        <InstaUploadModal
          open={openInstaUploadModal}
          setOpen={setOpenInstaUploadModal}
          data={instaImgList}
          setData={setInstaImgList}
          callback={() => {
            getFeedList(shopData.id).then((res) => {
              setFeedList(res.data.data.feedList);
            });
          }}
          endToCallback={() => {
            getNextList(response);
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  position: relative;

  justify-content: center;
`;

const NothingText = styled.span`
  color: #a0a6b1;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
`;

const PortfolioAddBtn = styled.div`
  &:hover {
    background-color: #fd7f96;
  }

  cursor: pointer;
  display: flex;
  width: 160px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #f14262;
  position: absolute;
  right: 0px;

  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;

  position: absolute;
  right: 0px;
  top: -64px;
`;

const AddOption = styled.div`
  cursor: pointer;
  display: flex;
  width: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  background-color: #f9fafb;

  position: absolute;
  top: -12px;
  right: 0px;
  z-index: 20;
  padding: 10px;
  overflow: hidden;

  & > div {
    display: flex;
    padding: 8px 17px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 50%;

    & > span {
      color: #a0a6b1;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }

    & > svg {
      color: #a0a6b1;
    }
  }

  & > div:hover {
    background-color: #f14262;
    & span {
      color: #ffffff;
    }
    & > svg {
      color: #ffffff;
    }
  }
`;

const FeedListBox = styled.div`
  display: flex;
  gap: 24px;
  width: 86%;
  height: 100%;
  flex-wrap: wrap;

  overflow-y: scroll;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Portfolio;
