import { styled } from "styled-components";
import MainLayout from "../../layout/MainLayout";
import { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import { ShopData } from "../../atoms/atom";
import MessageListener from "../../components/utility/MessageListener";
import { color_iris, color_midnight, color_rose } from "../../util/color";
import { IoBook, IoCalendarOutline, IoCard } from "react-icons/io5";
import { getAccount, getSettlementList } from "../../apis/settlement";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ko from "date-fns/locale/ko";
import { Locale } from "date-fns"; // Locale 타입 가져오기
import AccountInfoModal from "../../components/adjustment/AccountInfoModal";
registerLocale("ko", ko as unknown as Locale);

const Adjustment = () => {
  const [zoom, setZoom] = useState(1.0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [datePickerPosition, setDatePickerPosition] = useState({
    top: 0,
    left: 0,
  });
  const calendarButtonRef = useRef<HTMLDivElement>(null);

  const shopData = useRecoilValue(ShopData);

  const [active, setActive] = useState(0);
  const [active2, setActive2] = useState(0);

  const [isMonth, setIsMonth] = useState(false);

  const [settleList, setSettleList] = useState<any[]>([]);

  const [pageNum, setPageNum] = useState(1);

  const [updateCounter, setUpdateCounter] = useState(0);

  const [date, setDate] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [amount, setAmount] = useState(0);

  const [manager, setManager] = useState<any>({
    name: "전체",
    id: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [account, setAccount] = useState<any>({
    bank: "",
    account: "",
    accountHolder: "",
  });

  useEffect(() => {
    setStartDate(date);
    setEndDate(date);
  }, [date]);

  useEffect(() => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    } else {
      setZoom((document.body.scrollHeight - 40) / 885);
    }

    getAccount().then((res) => {
      console.log(res.data.data);
      if (
        !res.data.data.account ||
        !res.data.data.bank ||
        !res.data.data.accountHolder
      ) {
        setIsOpen(true);
      }

      setAccount(res.data.data);
    });

    getSettlementList(1, date.toISOString()).then((res) => {
      console.log(res.data.data.settlement);
      setSettleList(res.data.data.settlement);
      setAmount(res.data.data.price);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    active,
    active2,
    pageNum,
    updateCounter,
    date,
    manager,
    isMonth,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (showDatePicker && calendarButtonRef.current) {
      const rect = calendarButtonRef.current.getBoundingClientRect();
      setDatePickerPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [showDatePicker]);

  return (
    <MainLayout active={"adjustment"}>
      <Container $zoom={zoom}>
        <Main>
          <Contents>
            <Content1>
              <h2>어서오세요! {shopData.name}님</h2>
              <span>핑프와 오늘도 좋은 하루 되세요 🙂</span>
            </Content1>
            <Content2>
              {/* 상단 박스 */}
              <div style={{ display: "flex", width: "100%", gap: "20px" }}>
                <div
                  style={{
                    width: "600px",
                    height: "160px",
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    boxSizing: "border-box",
                    padding: "20px 30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <span>
                      {date.toLocaleDateString("ko-KR", {
                        year: "numeric",
                        month: "short",
                      })}{" "}
                      정산 예정 금액
                    </span>
                    <div
                      style={{
                        backgroundColor: "#FF5D7A",
                        width: "74px",
                        height: "38px",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "4px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowDatePicker(true)}
                      ref={calendarButtonRef}
                    >
                      <IoCalendarOutline style={{ fontSize: "18px" }} />
                      조회
                    </div>
                  </div>
                  <span
                    style={{
                      alignSelf: "flex-end",
                      fontWeight: "700",
                      fontSize: "22px",
                    }}
                  >
                    {amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                  </span>
                  <div
                    style={{
                      width: "96px",
                      height: "96px",
                      backgroundColor: "#FBC5D0",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "-20px",
                      right: "-20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IoCard
                      style={{
                        color: color_rose.rose8,
                        fontSize: "40px",
                        position: "relative",
                        top: "5px",
                        left: "-5px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "600px",
                    height: "160px",
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    boxSizing: "border-box",
                    padding: "20px 30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <span>계좌정보</span>
                    <span style={{ fontSize: "14px" }}>
                      정산금은 익월 15일 정산됩니다.
                    </span>
                  </div>
                  <span
                    style={{
                      alignSelf: "flex-end",
                      fontWeight: "700",
                      fontSize: "22px",
                    }}
                  >
                    {account.bank} {account.account} {account.accountHolder}
                  </span>
                  <div
                    style={{
                      width: "96px",
                      height: "96px",
                      backgroundColor: color_iris.iris3,
                      borderRadius: "50%",
                      position: "absolute",
                      top: "-20px",
                      right: "-20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IoBook
                      style={{
                        color: color_iris.iris9,
                        fontSize: "40px",
                        position: "relative",
                        top: "5px",
                        left: "-5px",
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* 정산 리스트 */}
              <div
                style={{
                  width: "100%",
                  height: "580px",
                  backgroundColor: "#ffffff",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "24px",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "16px",
                      backgroundColor: color_midnight.midnight1,
                      height: "44px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: "500",
                      }}
                    >
                      분류
                    </span>
                    <span
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: "500",
                      }}
                    >
                      고객정보
                    </span>
                    <span
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: "500",
                      }}
                    >
                      결제금액
                    </span>
                    <span
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: "500",
                      }}
                    >
                      결제방법
                    </span>
                    <span
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: "500",
                      }}
                    >
                      담당자
                    </span>
                    <span
                      style={{
                        flex: 1.3,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: "500",
                      }}
                    >
                      정산등록일
                    </span>
                    <span
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: "500",
                      }}
                    >
                      정산 여부
                    </span>
                  </div>

                  <DataList>
                    {settleList.map((item, idx) => (
                      <div
                        key={idx}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "16px",
                          backgroundColor: "#F9FAFB",
                          height: "44px",
                          alignItems: "center",
                          cursor: "pointer",
                          marginBottom: "12px",
                        }}
                        onClick={() => {
                          console.log(123);
                        }}
                        className="salesTableItem"
                      >
                        <span
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "16px",
                            color: "#000000",
                            fontWeight: "500",
                          }}
                        >
                          {item.type === "book"
                            ? "예약"
                            : item.type === "noShow"
                            ? "노쇼"
                            : "취소"}
                        </span>
                        <span
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "16px",
                            color: "#000000",
                            fontWeight: "500",
                          }}
                        >
                          {item.customerName}
                        </span>
                        <span
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "16px",
                            color: "#000000",
                            fontWeight: "500",
                          }}
                        >
                          {item.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>

                        <span
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "16px",
                            color: "#000000",
                            fontWeight: "500",
                          }}
                        >
                          앱 결제
                        </span>
                        <span
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "16px",
                            color: "#000000",
                            fontWeight: "500",
                          }}
                        >
                          {item.managerName}
                        </span>
                        <span
                          style={{
                            flex: 1.3,
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "16px",
                            color: "#000000",
                            fontWeight: "500",
                          }}
                        >
                          {new Date(item.createdAt).toLocaleDateString(
                            "ko-KR",
                            { year: "numeric", month: "short", day: "2-digit" }
                          )}
                        </span>
                        <span
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "16px",
                            color: "#000000",
                            fontWeight: "500",
                          }}
                        >
                          {item.isComputed ? "정산 완료" : "정산 예정"}
                        </span>
                      </div>
                    ))}
                  </DataList>
                </div>
              </div>
            </Content2>
          </Contents>
        </Main>
        {showDatePicker && (
          <DatePickerContainer
            style={{
              top: datePickerPosition.top,
              left: datePickerPosition.left,
            }}
          >
            <DatePicker
              selected={date}
              onChange={(e) => {
                if (e) {
                  setDate(e);
                  setShowDatePicker(false);
                }
              }}
              dateFormat="yyyy년 MM월"
              showMonthYearPicker
              inline
            />
          </DatePickerContainer>
        )}
        <MessageListener />
      </Container>
      {isOpen && (
        <AccountInfoModal
          open={isOpen}
          setOpen={setIsOpen}
          callback={() => {
            setUpdateCounter((old) => old + 1);
          }}
        />
      )}
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;
  zoom: ${(props) => props.$zoom};

  & .salesTableItem:hover {
    background-color: #f9e8ec !important;
  }
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-width: 1200px;
`;

const Contents = styled.div`
  width: 1200px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Content1 = styled.div`
  min-width: 400px;
  height: 51px;
  margin-left: 15px;

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;
  }
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 720px;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
`;

const DataList = styled.div`
  width: 100%;
  gap: 4px;
  height: 472px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DatePickerContainer = styled.div`
  position: absolute;
  z-index: 1000;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export default Adjustment;
