import axios from "axios";
import { baseUrl, config } from "./api";

export const getSettlementList = async (offset: number, date: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/settlement`,
    config(undefined, {
      limit: 100,
      offset: offset,
      date: date,
    })
  );
};

export const getAccount = async () => {
  return await axios.get(baseUrl + `/admin/shop/settlement/account`, config());
};

export const saveAccount = async (data: any) => {
  return await axios.put(
    baseUrl + "/admin/shop/settlement/account",
    data,
    config()
  );
};

export const getBank = async () => {
  return await axios.get(baseUrl + `/admin/shop/settlement/bank`, config());
};
